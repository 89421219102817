import { useLocaleCode } from '../../lib/hooks/useLocaleCode';
import { useQueryWrapper } from '../query';
import { queryCelestialEventsMultipleDaysByLocationId, queryCelestialEventsSingleDayByLocationId } from './queries';

export function useFetchCelestialEventsSingleDayByLocationId({ locationId }: { locationId?: string }) {
  const localeCode = useLocaleCode();
  return useQueryWrapper({
    ...queryCelestialEventsSingleDayByLocationId({ locationId, localeCode }),
    enabled: locationId != null
  });
}

export function useFetchCelestialEventsMultipleDaysByLocationId({ locationId }: { locationId?: string }) {
  return useQueryWrapper({
    ...queryCelestialEventsMultipleDaysByLocationId({ locationId }),
    enabled: locationId != null
  });
}
