import { Fragment } from 'react';
import settings from '../../app/settings';
import { useTranslate } from '../../lib/hooks/useTranslate';
import { createStaticMap } from '../../lib/map/static';
import { template } from '../../lib/string';
import { ILocation } from '../../model/location';
import './StaticMap.scss';

interface IProps {
  location: ILocation;
  width: number;
  height: number;
  paddingBottom?: number;
  tileZ: number;
  debug?: boolean;
}

export function StaticMap(props: IProps) {
  const { location, width, height, paddingBottom, tileZ, debug = false } = props;

  const translate = useTranslate();
  const data = createStaticMap({
    position: location.position,
    width,
    height,
    paddingBottom,
    tileZ
  });

  return (
    <div
      className="static-map"
      role="img"
      aria-label={translate('staticMap/screenReaderLabel', { name: location.name })}
    >
      <svg
        className="static-map__map"
        width={data.width}
        height={data.height}
        viewBox={`0 0 ${data.width} ${data.height}`}
        preserveAspectRatio="xMidYMid slice"
      >
        {data.tiles.map(tile => {
          return (
            <Fragment key={`${tile.xyz.x}/${tile.xyz.y}/${tile.xyz.z}`}>
              <image
                className="forecast-page__static-map-tile"
                xlinkHref={template(settings.external.map.raster, tile.xyz)}
                href={template(settings.external.map.raster, tile.xyz)}
                x={tile.position.x}
                y={tile.position.y}
                width={512}
                height={512}
              />

              {debug && (
                <rect
                  x={tile.position.x}
                  y={tile.position.y}
                  width="512"
                  height="512"
                  fill="none"
                  stroke="black"
                  strokeDasharray="5, 5"
                />
              )}
            </Fragment>
          );
        })}

        {debug && (
          <>
            {/* Position crosshair */}
            <line
              x1={data.center.x - 10}
              y1={data.center.y - 5}
              x2={data.center.x + 10}
              y2={data.center.y + 5}
              stroke="red"
              strokeWidth={2}
            />

            <line
              x1={data.center.x - 10}
              y1={data.center.y + 5}
              x2={data.center.x + 10}
              y2={data.center.y - 5}
              stroke="red"
              strokeWidth={2}
            />

            {/* Blue outline around static map */}
            <rect x="2" y="2" width={width - 4} height={height - 4} fill="none" stroke="navy" strokeWidth={4} />
          </>
        )}
      </svg>

      {/* The parent div has role img and alt text so this image does not need it  */}
      {/* eslint-disable-next-line jsx-a11y/alt-text */}
      <img className="static-map__pin" src="/assets/images/map/marker-primary.png" />
    </div>
  );
}
