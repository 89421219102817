import classNames from 'classnames';
import { getPrecipitationDisplayValue } from '@nrk/yr-helpers';
import { numberToString } from '../../lib/format';
import { useTranslate } from '../../lib/hooks/useTranslate';
import styles from './Precipitation.module.scss';

interface IProps {
  value: number;
  className?: string;
  valueClassName?: string;
  unitClassName?: string;
  tone?: 'primary' | 'secondary' | 'plain';
  /**
   *  - Defaults to 'automatic'
   *  - 'automatic' - renders one decimal up to 9.9, rounds everything above
   *  - true - renders with one decimal regardless of value
   *  - false - rounds all numbers
   */
  decimal?: boolean | 'automatic';
  /**
   * - Defaults to true
   * - Add `mm` after the value
   */
  displayUnit?: boolean;
  /**
   * - Defaults to true
   * - The entire element is invisible if the displayed value would be 0
   */
  displayZero?: boolean;
  /**
   * - Defaults to true
   * - Should be set to false when used in table
   *   where the table header already explains what the value is.
   */
  renderScreenReaderLabel?: boolean;
}

export const Precipitation = (props: IProps) => {
  const {
    value,
    decimal = 'automatic',
    className,
    valueClassName,
    unitClassName,
    displayUnit = true,
    displayZero = true,
    renderScreenReaderLabel = true,
    tone = 'primary'
  } = props;

  const translate = useTranslate();

  const unitShort = translate('units/millimeter/short');
  const unitLong = translate('units/millimeter/long');
  const label = translate('terminology/precipitation/long');
  const { displayValue, displayDecimal } = getPrecipitationDisplayValue({ value, decimal });

  return (
    <span
      className={classNames(styles.main, className)}
      role="text"
      data-testid="precipitation"
      data-tone={tone}
      data-invisible={displayZero === false && value === 0}
    >
      {renderScreenReaderLabel && <span className="nrk-sr">{label} </span>}

      <span className={valueClassName}>
        {numberToString(displayValue, translate, displayDecimal, {
          caller: '<Precipitation>',
          originalValue: value
        })}
      </span>

      {displayUnit && (
        <abbr className={classNames(styles.unit, unitClassName)} title={unitLong}>
          {unitShort}
        </abbr>
      )}
    </span>
  );
};
