import { useTranslate } from '../../lib/hooks/useTranslate';
import { Temperature } from '../Temperature/Temperature';
import './FeelsLikeText.scss';

interface IProps {
  feelsLike: number;
  decimal?: boolean;
}

export function FeelsLikeText(props: IProps) {
  const { feelsLike, decimal } = props;
  const translate = useTranslate();

  return (
    <div className="feels-like-text">
      {`${translate('terminology/feelsLike/long')} `}
      <Temperature value={feelsLike} decimal={decimal} renderScreenReaderLabel={false} />
    </div>
  );
}
