import { useTranslate } from '../../lib/hooks/useTranslate';
import { ClickableLink } from '../Clickable/Clickable';
import { Text } from '../Text/Text';
import './SaveAs.scss';

interface IProps {
  type: 'svg' | 'pdf';
  url: string;
}

export const SaveAs = (props: IProps) => {
  const { type, url } = props;
  const translate = useTranslate();

  const text = type === 'pdf' ? translate('saveAsPdf/text') : translate('saveAsSvg/text');

  return (
    <ClickableLink target="_blank" className="save-as" href={url}>
      <Text size="5" isLink={true}>
        {text}
      </Text>
    </ClickableLink>
  );
};
