import time from '@nrk/yr-time';
// @ts-ignore
import spline from '@nrk/yr-catmull-rom-spline';
// @ts-ignore
import numberUtils from '@nrk/yr-number-utils';
import { INowcastPoint } from '../model/nowcast';
import { IMinute } from '../model/times';

interface IGradientStop {
  colour: string;
  value: number;
}

const BASE_FONT_SIZE = 15;
const MAX_PRECIPITATION_INTENSITY = 9;
const TEMPERATURE_GRAD: { [key: number]: string } = {
  50: '#c60000',
  0.000000000000000001: '#c60000',
  0: '#0077cc',
  '-50': '#0077cc'
};

// Distance between each y axis tick in the various graphs in that graph's unit.
const GRAPH_Y_AXIS_STRIDE: { [key: string]: number } = {
  precipitation: 1,
  snowDepth: 1,
  temperature: 2,
  wind: 3
};

// value/em
const DETAILED_GRAPH_VALUE_SCALE: { [key: string]: number } = {
  precipitation: 1.4 / GRAPH_Y_AXIS_STRIDE.precipitation,
  temperature: 1.4 / GRAPH_Y_AXIS_STRIDE.temperature,
  wind: 1.4 / GRAPH_Y_AXIS_STRIDE.wind
};

// Temporary solution untill we find a different color than red to show for extreme wind values
const WIND_GRAD: { [key: string]: string } = {
  100: '#26292a',
  0: '#26292a'
};

/*
const WIND_GRAD: { [key: string]: string } = {
  100: '#ad0014',
  17.2: '#ad0014', // "Gale" in the Beaufort scale, matches the extreme wind value specified in `app/settings.js`
  '17.1999999999999999': '#26292a',
  0: '#26292a'
};
*/

const temperatureColourGrad: IGradientStop[] = [];
let temperatureColourSvg = '';
const windColourGrad: IGradientStop[] = [];
let windColourSvg = '';

init();

function init() {
  function sort(a: IGradientStop, b: IGradientStop): 1 | -1 {
    return a.value < b.value ? 1 : -1;
  }

  for (const key in TEMPERATURE_GRAD) {
    if (TEMPERATURE_GRAD.hasOwnProperty(key)) {
      temperatureColourGrad.push({
        colour: TEMPERATURE_GRAD[key],
        value: parseFloat(key)
      });
    }
  }

  temperatureColourGrad.sort(sort);
  temperatureColourSvg = generateColoursSvg(temperatureColourGrad, 'temperature');

  for (const key in WIND_GRAD) {
    if (WIND_GRAD.hasOwnProperty(key)) {
      windColourGrad.push({
        colour: WIND_GRAD[key],
        value: parseFloat(key)
      });
    }
  }

  windColourGrad.sort(sort);
  windColourSvg = generateColoursSvg(windColourGrad, 'wind');
}

/**
 * Convert 'ems' to pixels
 */
export function emsToPixels(ems: number): number {
  return Math.round(ems * BASE_FONT_SIZE);
}

/**
 * Convert pixels to 'rem'
 */
export function pixelValueToRem(pixels: number) {
  return `${pixels / BASE_FONT_SIZE}rem`;
}

/**
 * Retrieve css size from 'value' for 'type'
 */
export function sizeFromValue(value: number, type: string): number {
  if (type in DETAILED_GRAPH_VALUE_SCALE) {
    return numberUtils.round(DETAILED_GRAPH_VALUE_SCALE[type] * value, 2);
  }

  return 0;
}

/**
 * Retrieve svg gradient definition string
 * @returns {String}
 */
export function getSvgGradientDefinitions() {
  return temperatureColourSvg + windColourSvg;
}

/**
 * Retrieve a SVG path description from an array of precipitation values
 */
export function precipitationPathDescriptionFromNowcastPoints({
  points,
  targetDuration,
  width,
  height
}: {
  points: INowcastPoint[];
  targetDuration: IMinute;
  width: number;
  height: number;
}): string {
  const startTime = time.create(points[0].time);
  const targetEndTime = startTime.add(targetDuration, 'minutes');

  // Don't include points that are more than `targetDuration` minutes after the start time.
  const pointsToInclude = points.filter(point => {
    const pointTime = time.create(point.time);

    return pointTime.isBefore(targetEndTime) || pointTime.isSame(targetEndTime);
  });

  // Calculate the duration of the points we are including.
  const pointsToIncludeEndTime = time.create(pointsToInclude[pointsToInclude.length - 1].time);
  const pointsToIncludeDuration = pointsToIncludeEndTime.diff(startTime, 'minutes');

  const coordinates = pointsToInclude.map(point => {
    const minute = time.create(point.time).diff(startTime, 'minutes');

    const x = (minute / targetDuration) * width;
    const y = coordinateFromSquaredNowPrecipitationIntensity(point.precipitation.intensity, height);

    return [x, y];
  });

  const plot = spline.points(coordinates);

  // The width should be equal to the target duration, but if the duration of points to include is less
  // we need to adjust it correspondingly.
  const pathWidth = (pointsToIncludeDuration / targetDuration) * width;

  return `${spline.svgPath(plot)} L ${pathWidth} ${height} L 0 ${height} Z`;
}

/**
 * Retrieve Y coordinate for now precipitation 'value' (for drawing a graph)
 * The coordinates are transposed using Math.sqrt() to make it possible to
 * see small precipitation values such as 0.3 in a graph with a max value of 10.
 * See #YR-1381.
 */
export function coordinateFromSquaredNowPrecipitationIntensity(value: number, maxHeight: number): number {
  const maxPrecipitationIntensitySquared = Math.sqrt(MAX_PRECIPITATION_INTENSITY);
  const valueSquared = Math.sqrt(value);

  if (valueSquared >= maxPrecipitationIntensitySquared) {
    return 0;
  }

  return Math.round(maxHeight - (valueSquared / maxPrecipitationIntensitySquared) * maxHeight);
}

/**
 * Generate svg gradient definition for 'type'
 */
function generateColoursSvg(grad: IGradientStop[], type: string): string {
  const min = grad[grad.length - 1].value;
  const max = grad[0].value;
  const height = emsToPixels(sizeFromValue(max - min, type));
  let svg = `<svg id="${type}GradDefs" class="nrk-sr" aria-hidden="true"><defs><linearGradient id="${type}Grad" x1="0" y1="0" x2="0" y2="${height}" gradientUnits="userSpaceOnUse" spreadMethod="pad">`;

  grad.forEach(stop => {
    svg += `<stop offset="${1 - numberUtils.normalize(stop.value, min, max)}" stop-color="${
      stop.colour
    }" stop-opacity="1"></stop>`;
  });

  svg += '</linearGradient></defs></svg>';

  return svg;
}
