import { useQueryWrapper } from '../query';
import { useFetchLocation } from '../locations/hooks';
import { queryNowcastByLocationId } from './queries';

export function useFetchNowcastByLocationId({ locationId }: { locationId?: string }) {
  const { data: location } = useFetchLocation({ locationId });

  return useQueryWrapper({
    ...queryNowcastByLocationId({ locationId }),
    enabled: location != null && location.hasNowcast
  });
}
