import { getXYZTileFromPosition, getXYZTileWithFractionFromPosition, wrapTileX } from '@nrk/yr-map';
import { IPosition } from '../../model/position';

interface IStaticTile {
  position: {
    x: number;
    y: number;
  };
  xyz: {
    x: number;
    y: number;
    z: number;
  };
}

const STATIC_MAP_TILE_SIZE = 512;

export function createStaticMap({
  position,
  width,
  height,
  paddingBottom = 0,
  tileZ
}: {
  position: IPosition;
  width: number;
  height: number;
  paddingBottom?: number;
  tileZ: number;
}) {
  const positionTileWithFraction = getXYZTileWithFractionFromPosition({ position, tileZ });
  const positionTile = getXYZTileFromPosition({ position, tileZ });

  const positionOffsetInPixels = {
    x: Math.round(STATIC_MAP_TILE_SIZE * (positionTileWithFraction.x - positionTile.x)),
    y: Math.round(STATIC_MAP_TILE_SIZE * (positionTileWithFraction.y - positionTile.y))
  };

  // The offset of the position within the position tile
  const positionOffset = {
    top: positionOffsetInPixels.y,
    bottom: STATIC_MAP_TILE_SIZE - positionOffsetInPixels.y,
    left: positionOffsetInPixels.x,
    right: STATIC_MAP_TILE_SIZE - positionOffsetInPixels.x
  };

  // Calculate how many more tiles we need to add to the left, right, top and bottom
  // in order to have enough tiles to cover the given width * height.
  const tilesTop = Math.ceil((height / 2 - positionOffset.top) / STATIC_MAP_TILE_SIZE);
  const tilesBottom = Math.ceil((height / 2 - positionOffset.bottom + paddingBottom) / STATIC_MAP_TILE_SIZE);
  const tilesLeft = Math.ceil((width / 2 - positionOffset.left) / STATIC_MAP_TILE_SIZE);
  const tilesRight = Math.ceil((width / 2 - positionOffset.right) / STATIC_MAP_TILE_SIZE);

  const horizontalTiles = tilesLeft + tilesRight + 1;
  const verticalTiles = tilesTop + tilesBottom + 1;

  // The offset of the position within the grid
  const gridPositionOffset = {
    x: STATIC_MAP_TILE_SIZE * tilesLeft + positionOffsetInPixels.x,
    y: STATIC_MAP_TILE_SIZE * tilesTop + positionOffsetInPixels.y
  };

  // How much to offset the grid to make the position centered within the map viewport
  const gridOffset = {
    x: gridPositionOffset.x - width / 2,
    y: gridPositionOffset.y - height / 2
  };

  const tiles: IStaticTile[] = [];
  for (let verticalIndex = 0; verticalIndex < verticalTiles; verticalIndex++) {
    for (let horizontalIndex = 0; horizontalIndex < horizontalTiles; horizontalIndex++) {
      const x = wrapTileX(positionTile.x + (horizontalIndex - tilesLeft), tileZ);
      const y = positionTile.y + (verticalIndex - tilesTop);
      const z = positionTile.z;

      tiles.push({
        position: {
          x: STATIC_MAP_TILE_SIZE * horizontalIndex - gridOffset.x,
          y: STATIC_MAP_TILE_SIZE * verticalIndex - gridOffset.y
        },
        xyz: { x, y, z }
      });
    }
  }

  return {
    width,
    height,
    center: {
      x: width / 2,
      y: height / 2
    },
    tiles
  };
}
