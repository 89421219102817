import time from '@nrk/yr-time';
import { INowcastPoint } from '../../../model/nowcast';
import { ITranslateFunction } from '../../../model/translate';

const HEAVY_PRECIPITATION_LIMIT = 0.95;
const MODERATE_PRECIPITATION_LIMIT = 0.25;
const LIGHT_PRECIPITATION_LIMIT = 0.1;

type TPrecipitationIntensityType = 'heavy' | 'moderate' | 'light' | 'zero';

// nowGraph/summary/next30Minutes/min: Neste 30 minutter {minIntensityLabel}
// nowGraph/summary/next30Minutes/minAndMax: Neste 30 minutter {minIntensityLabel} til {maxIntensityLabel}
// nowGraph/summary/next30To60Minutes/min: Om 30 til 60 minutter {minIntensityLabel}
// nowGraph/summary/next30To60Minutes/minAndMax: Om 30 til 60 minutter {minIntensityLabel} til {maxIntensityLabel}
// nowGraph/summary/next60To90Minutes/min: Om 60 til 90 minutter {minIntensityLabel}
// nowGraph/summary/next60To90Minutes/minAndMax: Om 60 til 90 minutter {minIntensityLabel} til {maxIntensityLabel}
export function createNowcastScreenReaderSummary(points: INowcastPoint[], translate: ITranslateFunction) {
  const next30Minutes: number[] = [];
  const next30To60Minutes: number[] = [];
  const next60To90Minutes: number[] = [];

  if (points.length === 0) {
    return '';
  }

  const initialTime = time.create(points[0].time);

  for (const point of points) {
    const pointTime = time.create(point.time);
    const minutesSinceInitialPoint = pointTime.diff(initialTime, 'minutes');

    if (minutesSinceInitialPoint < 30) {
      next30Minutes.push(point.precipitation.intensity);
      continue;
    }

    if (minutesSinceInitialPoint < 60) {
      next30To60Minutes.push(point.precipitation.intensity);
      continue;
    }

    // We could potentially get points further in time than 90 minutes
    // so we check here as well instead of just taking all the remaining points.
    // The last intensity will be "lost" here since the API gives us one more
    // intensity interval after the 90 minutes.
    if (minutesSinceInitialPoint < 90) {
      next60To90Minutes.push(point.precipitation.intensity);
    }
  }

  // This should not happen, but in case the API does not give us enough points to add intensity
  // to all our groups we just return an empty string.
  if (next30Minutes.length === 0 || next30To60Minutes.length === 0 || next60To90Minutes.length === 0) {
    return '';
  }

  const next30MinutesIntensityLabels = getIntensityLabels(next30Minutes, translate);
  const next30MinutesDescription =
    next30MinutesIntensityLabels.maxIntensityLabel == null
      ? translate('nowGraph/summary/next30Minutes/min', next30MinutesIntensityLabels)
      : translate('nowGraph/summary/next30Minutes/minAndMax', next30MinutesIntensityLabels);

  const next30To60MinutesIntensityLabels = getIntensityLabels(next30To60Minutes, translate);
  const next30To60MinutesDescription =
    next30To60MinutesIntensityLabels.maxIntensityLabel == null
      ? translate('nowGraph/summary/next30To60Minutes/min', next30To60MinutesIntensityLabels)
      : translate('nowGraph/summary/next30To60Minutes/minAndMax', next30To60MinutesIntensityLabels);

  const next60To90MinutesIntensityLabels = getIntensityLabels(next60To90Minutes, translate);
  const next60To90MinutesDescription =
    next60To90MinutesIntensityLabels.maxIntensityLabel == null
      ? translate('nowGraph/summary/next60To90Minutes/min', next60To90MinutesIntensityLabels)
      : translate('nowGraph/summary/next60To90Minutes/minAndMax', next60To90MinutesIntensityLabels);

  return `${next30MinutesDescription}. ${next30To60MinutesDescription}. ${next60To90MinutesDescription}.`;
}

function getIntensityLabels(
  intensities: number[],
  translate: ITranslateFunction
): { minIntensityLabel: string; maxIntensityLabel?: string } {
  const minIntensity = Math.min(...intensities);
  const maxIntensity = Math.max(...intensities);

  const minIntensityType = getIntensityType(minIntensity);
  const maxIntensityType = getIntensityType(maxIntensity);

  // If min and max have the same intensity type we only want to show
  // a single description to the user.
  if (minIntensityType === maxIntensityType) {
    return {
      minIntensityLabel: translate(`precipitation/intensity/${minIntensityType}`)
    };
  }

  return {
    minIntensityLabel: translate(`precipitation/intensity/${minIntensityType}`),
    maxIntensityLabel: translate(`precipitation/intensity/${maxIntensityType}`)
  };
}

function getIntensityType(intensity: number): TPrecipitationIntensityType {
  if (intensity >= HEAVY_PRECIPITATION_LIMIT) {
    return 'heavy';
  } else if (intensity >= MODERATE_PRECIPITATION_LIMIT) {
    return 'moderate';
  } else if (intensity >= LIGHT_PRECIPITATION_LIMIT) {
    return 'light';
  }

  return 'zero';
}
