import { Component } from 'react';
import { captureException } from '../../lib/errorReporter';
import { isChunkLoadError } from '../ErrorBoundary/ErrorBoundary';

interface IProps {
  children: React.ReactNode;
}

interface IState {
  hasError: boolean;
}

export class ComponentErrorBoundary extends Component<IProps, IState> {
  static getDerivedStateFromError(error: Error) {
    // If the error is a ChunkLoadError, we want the root ErrorBoundary
    // to handle it as we only want that logic in one place. hence we throw
    // the error further up the chain so that ErrorBoundary gets it.
    if (isChunkLoadError(error)) {
      throw error;
    }

    captureException({ error, logger: 'ComponentErrorBoundary' });

    // Update state so the next render will show the fallback UI
    return {
      hasError: true
    };
  }

  constructor(props: IProps) {
    super(props);

    this.state = {
      hasError: false
    };
  }

  render() {
    const { children } = this.props;
    const { hasError } = this.state;

    if (hasError) {
      return null;
    }

    return children;
  }
}
