import { Icon } from '@nrk/yr-icons';
import { convertSymbolKeyToId } from '@nrk/yr-weather-symbols';
import classNames from 'classnames';
import { useCallback, useEffect, useRef, useState } from 'react';
import { createPageUrl } from '../../app/redirects';
import { track } from '../../lib/analytics/track';
import { getDetailedWindDescriptionWithDirection } from '../../lib/helpers/windDescriptionHelpers';
import { useLocaleCode } from '../../lib/hooks/useLocaleCode';
import { useTranslate } from '../../lib/hooks/useTranslate';
import { sentenceCase } from '../../lib/string';
import { ICurrentHour } from '../../model/currentHour';
import { ILocation } from '../../model/location';
import { INowcast } from '../../model/nowcast';
import { FeelsLikeText } from '../FeelsLikeText/FeelsLikeText';
import { InfoButton } from '../InfoButton/InfoButton';
import { InfoPanel } from '../InfoPanel/InfoPanel';
import { NowGraph } from '../NowGraph/NowGraph';
import { Precipitation } from '../Precipitation/Precipitation';
import { PulseIcon } from '../PulseIcon/PulseIcon';
import { StaticMap } from '../StaticMap/StaticMap';
import { WeatherSymbol } from '../WeatherSymbol/WeatherSymbol';
import { Temperature } from '../Temperature/Temperature';
import { Wind } from '../Wind/Wind';
import './NowHero.scss';
import { Text } from '../Text/Text';
import { Heading } from '../Heading/Heading';

const NOWCAST_GRAPH_DURATION = 90;

interface IProps {
  location: ILocation;
  currentHour: ICurrentHour;
  nowcast?: INowcast;
}

export const NowHero = (props: IProps) => {
  const { location, currentHour, nowcast } = props;
  const translate = useTranslate();

  const nowcastPrecipitationSum = getNowcastPrecipitationSum(nowcast);
  const showNowGraph = location.hasNowcast === true && nowcastPrecipitationSum != null && nowcastPrecipitationSum > 0;
  const showNowGraphError = location.hasNowcast === true && nowcastPrecipitationSum == null;
  const showNowGraphNoPrecipitation = location.hasNowcast === true && nowcastPrecipitationSum === 0;

  const localeCode = useLocaleCode();
  const slideContainerRef = useRef<HTMLDivElement>(null);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [currentSlideButton, setCurrentSlideButton] = useState(0);
  const [initiallyShowNowGraphAsFirstSlide, setInitiallyShowNowGraphAsFirstSlide] = useState(showNowGraph);
  const [showNowGraphInfoPanel, setShowNowGraphInfoPanel] = useState(false);

  // We want to show the nowcast slide by default when it exists.
  // We can't set the initial scroll position using CSS,
  // and setting the scroll position using JavaScript is visually noticeable
  // since it can take a while for JavaScript to start when loading a
  // page with a now hero directly.
  // To get the desired behaviour we use CSS to reorder the slides
  // so the now cast slide is the first slide, and when this component
  // is first mounted we stop reordering and scroll the now cast slide into view.
  useEffect(() => {
    if (initiallyShowNowGraphAsFirstSlide === false) {
      return;
    }

    if (slideContainerRef.current == null) {
      return;
    }

    const slideContainer = slideContainerRef.current;

    // If the user has managed to scroll the now hero before this effect runs
    // we don't want to annoy them by reordering and scrolling the slides.
    if (slideContainer.scrollLeft !== 0) {
      return;
    }

    // We no longer need to show the now graph slide as the first slide
    // since we can scroll the now graph slide into view using JavaScript.
    setInitiallyShowNowGraphAsFirstSlide(false);
  }, [initiallyShowNowGraphAsFirstSlide]);

  // Slide the now graph slide into view when `initiallyShowNowGraphAsFirstSlide` is no longer true
  // and the now graph slide, which should be the initially visible slide, has become slide number two.
  useEffect(() => {
    if (initiallyShowNowGraphAsFirstSlide === true) {
      return;
    }

    if (slideContainerRef.current == null) {
      return;
    }

    const slideContainer = slideContainerRef.current;
    const slideContainerWidth = Math.round(slideContainer.getBoundingClientRect().width);

    // Slide the now graph slide, which is the second slide, into view
    slideContainer.scrollLeft = slideContainerWidth * 1;
  }, [initiallyShowNowGraphAsFirstSlide]);

  useEffect(() => {
    if (slideContainerRef.current == null) {
      return;
    }

    const slideContainer = slideContainerRef.current;
    const slideContainerWidth = Math.round(slideContainer.getBoundingClientRect().width);

    // Slide the now graph slide (which is slide number two) into view
    slideContainer.scrollLeft = currentSlide === 0 ? 0 : slideContainerWidth * 1;

    setCurrentSlideButton(currentSlide);
  }, [currentSlide]);

  const toggleNowGraphInfoPanel = useCallback(() => {
    if (showNowGraphInfoPanel === false) {
      track.event({ category: 'now_header', action: 'open_nowcast_info' });
    }

    setShowNowGraphInfoPanel(!showNowGraphInfoPanel);
  }, [showNowGraphInfoPanel]);

  // Check which current slide is active when the user swipes the slides
  function onSwipe(event: any) {
    const slideContainer = event.currentTarget;

    // Round width and scroll left values so we don't get any rounding errors caused by subpixel values
    const slideContainerWidth = Math.round(slideContainer.getBoundingClientRect().width);
    const slideContainerScrollLeft = Math.round(slideContainer.scrollLeft);

    // If the first slide is fully visible set it as the current slide
    if (slideContainerScrollLeft === 0) {
      setCurrentSlide(0);
      return;
    }

    // If the second slide is fully visible set it as the current slide and don
    if (slideContainerScrollLeft === slideContainerWidth) {
      setCurrentSlide(1);
      return;
    }

    // Detect which slide is currently most visible.
    // We consider a slide to be the most visible if more than 50% of it is visible.
    // To calculate this we pretend the user has scrolled half a slide width
    // further than they really have and determine which would be the
    // leftmost visible slide if that were the case.
    const adjustedSlideContainerScrollLeft = slideContainerScrollLeft + slideContainerWidth / 2;
    const newCurrentSlideButton = Math.floor(adjustedSlideContainerScrollLeft / slideContainerWidth);

    setCurrentSlideButton(newCurrentSlideButton);
  }

  const numberOfSlides = location.hasNowcast === true ? 2 : 1;

  const mapPageUrl = createPageUrl({
    localeCode,
    pageId: 'map',
    subpageId: 'weather',
    locationId: location.id,
    urlPath: location.urlPath
  });

  return (
    <div
      className={classNames('now-hero', {
        'now-hero--show-map': location.isCoordinate,
        'now-hero--show-now-graph-as-first-slide': initiallyShowNowGraphAsFirstSlide
      })}
      data-testid="now-hero__current-hour-slide"
    >
      {location.isCoordinate && (
        <a className="now-hero__static-map" href={mapPageUrl}>
          <StaticMap location={location} width={1440} height={328} paddingBottom={100} tileZ={4} debug={false} />
        </a>
      )}

      <div className="layout-container">
        {/*
          We use `tabIndex={0}` on the slides to make it easy for keyboard users
          to navigate between the slides on small screens where they're swipeable.
        */}
        <div className="now-hero__slide-container" ref={slideContainerRef} onScroll={onSwipe}>
          {/* eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex */}
          <div className="now-hero__slide now-hero__slide--next-hour" tabIndex={0}>
            <Heading level="h2" size="4" className="now-hero__heading">
              <div className="now-hero__heading-icon">
                <Icon id="icon-clock" size={2} />
              </div>

              {translate('nowHero/currentHourTitle')}
            </Heading>

            <div className="now-hero__next-hour-content">
              <div className="now-hero__next-hour-main">
                <div className="now-hero__next-hour-symbol">
                  <WeatherSymbol id={convertSymbolKeyToId(currentHour.symbolCode.next1Hour)} />
                </div>
                {currentHour.temperature != null && currentHour.temperature.value != null && (
                  <div className="now-hero__next-hour-temperature-main">
                    <Temperature value={currentHour.temperature.value} decimal={false} />
                  </div>
                )}
              </div>

              <div className="now-hero__next-hour-details">
                {currentHour.temperature != null && currentHour.temperature.value != null && (
                  <div className="now-hero__next-hour-detail">
                    <Icon className="now-hero__next-hour-icon" id="icon-temperature" />
                    <div className="now-hero__next-hour-text" role="text">
                      <div className="now-hero__next-hour-temperature-text">
                        <Temperature
                          value={currentHour.temperature.value}
                          decimal={false}
                          renderScreenReaderLabel={false}
                        />{' '}
                      </div>

                      <Text size="4" tabularNums={true}>
                        <FeelsLikeText
                          feelsLike={currentHour.temperature.feelsLike ?? currentHour.temperature.value}
                          decimal={false}
                        />
                      </Text>
                    </div>
                  </div>
                )}

                <div className="now-hero__next-hour-detail">
                  {currentHour.precipitation != null && currentHour.precipitation.value != null && (
                    <>
                      <Icon className="now-hero__next-hour-icon" id="icon-umbrella-open" />

                      <div className="now-hero__next-hour-text">
                        <Precipitation
                          value={currentHour.precipitation.value}
                          valueClassName="now-hero__next-hour-precipitation-value"
                        />
                      </div>
                    </>
                  )}
                </div>

                <div className="now-hero__next-hour-detail">
                  {currentHour.wind != null && (
                    <>
                      <Icon className="now-hero__next-hour-icon" id="icon-wind" />

                      <div className="now-hero__next-hour-text">
                        <Wind
                          displayArrow={true}
                          type="wind-and-gust"
                          windGust={currentHour.wind.gust}
                          decimal={false}
                          value={currentHour.wind.speed}
                          direction={currentHour.wind.direction}
                          className="now-hero__next-hour-wind"
                          valueClassName="now-hero__next-hour-wind-value"
                          screenReaderLabel={getDetailedWindDescriptionWithDirection(
                            currentHour.wind.speed,
                            currentHour.wind.direction,
                            translate,
                            currentHour.wind.gust
                          )}
                          debug={{ caller: 'NowHero', api: 'currentHour', locationId: location.id }}
                        />
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>

          {location.hasNowcast === true && (
            <div
              className="now-hero__slide now-hero__slide--now-graph"
              // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
              tabIndex={0}
              data-testid="now-hero__now-graph-slide"
            >
              <Heading level="h2" size="4" className="now-hero__heading">
                <div className="now-hero__heading-icon">
                  {showNowGraph ? <PulseIcon /> : <Icon id="icon-umbrella-open" size={2} />}
                </div>

                {showNowGraph
                  ? sentenceCase(translate('nowHero/precipitationNextXMinutes', { duration: NOWCAST_GRAPH_DURATION }))
                  : translate('nowHero/graphTitle')}
              </Heading>

              <div className="now-hero__now-graph-info-button-wrapper">
                <InfoButton id="now-hero__now-graph-info-button" onClick={toggleNowGraphInfoPanel} />
              </div>

              {showNowGraph && nowcast != null && (
                <div className="now-hero__now-graph">
                  <NowGraph points={nowcast.points} />
                </div>
              )}

              {showNowGraphError && <p className="now-hero__radar-error">{translate('nowHero/radarError')}</p>}

              {showNowGraphNoPrecipitation && (
                <p className="now-hero__no-precipitation">{translate('nowHero/noPrecipitation')}</p>
              )}

              {showNowGraphInfoPanel && (
                <InfoPanel
                  openedById="now-hero__now-graph-info-button"
                  title={translate('nowHero/infoPanel/heading')}
                  onClose={toggleNowGraphInfoPanel}
                >
                  <div className="now-hero__now-graph-info-panel">
                    <p>{translate('nowHero/infoPanel/body')}</p>
                  </div>
                </InfoPanel>
              )}
            </div>
          )}
        </div>

        {numberOfSlides > 1 && (
          // We intentionally hide the progress buttons from screen readers
          // and remove the buttons from keyboard users.
          // See https://nrknyemedier.atlassian.net/browse/YR-3590
          <div className="now-hero__progress" aria-hidden="true">
            <button
              tabIndex={-1}
              className="now-hero__progress-button"
              disabled={currentSlideButton === 0}
              onClick={() => {
                setCurrentSlide(0);
              }}
            >
              <span className="nrk-sr">{translate('nowHero/toolbar/nextHour')}</span>
            </button>
            <button
              tabIndex={-1}
              className="now-hero__progress-button"
              disabled={currentSlideButton === 1}
              onClick={() => {
                setCurrentSlide(1);
              }}
            >
              <span className="nrk-sr">{translate('nowHero/toolbar/nowcast')}</span>
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

function getNowcastPrecipitationSum(nowcast?: INowcast) {
  if (nowcast == null || nowcast.points.length === 0) {
    return undefined;
  }

  return nowcast.points.reduce((sum, point) => sum + point.precipitation.intensity, 0);
}
