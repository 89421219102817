import classNames from 'classnames';
import { ClickableButton, ClickableLink } from '../Clickable/Clickable';
import { Text } from '../Text/Text';
import './ViewSwitcher.scss';

export interface IView {
  id: string;
  for: string;
  displayName: string;
  href?: string;
  className?: string;
}

interface IProps {
  className?: string;
  width?: 'compact' | 'auto';
  views: IView[];
  activeViewId: string;
  onChangeView?: (view: IView) => void;
}

export const ViewSwitcher = (props: IProps) => {
  const { className, width = 'auto', views, activeViewId, onChangeView } = props;

  const clickable = views.map(view =>
    view.href ? (
      <ClickableLink
        key={view.id}
        htmlFor={view.for}
        className={classNames(
          { 'view-switcher__item': true, 'view-switcher__item--active': activeViewId === view.id },
          view.className
        )}
        href={view.href}
        data-testid="view-switcher__link"
        onClick={() => {
          if (onChangeView != null) {
            onChangeView(view);
          }
        }}
      >
        <Text size="4" weight="bold" color="inherit">
          {view.displayName}
        </Text>
      </ClickableLink>
    ) : (
      <ClickableButton
        key={view.id}
        htmlFor={view.for}
        className={classNames(
          { 'view-switcher__item': true, 'view-switcher__item--active': activeViewId === view.id },
          view.className
        )}
        type="button"
        data-testid="view-switcher__button"
        onClick={() => (props.onChangeView ? props.onChangeView(view) : null)}
      >
        <Text size="4" weight="bold" color="inherit">
          {view.displayName}
        </Text>
      </ClickableButton>
    )
  );

  return (
    <div
      className={classNames(
        'view-switcher',
        {
          'view-switcher--compact': width === 'compact',
          // If there is only one view we hide the view switcher
          'view-switcher--hidden': views.length <= 1
        },
        className
      )}
      data-testid="view-switcher"
    >
      <div className="view-switcher__content">{clickable}</div>
    </div>
  );
};
