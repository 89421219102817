import { Text } from '../Text/Text';
import { Timestamp } from '../Timestamp/Timestamp';
import { Translate } from '../Translate/Translate';
import './LastUpdated.scss';

interface IProps {
  created: string;
  type: 'coast' | 'forecast';
  showLink?: boolean;
}

export const LastUpdated = (props: IProps) => {
  const { created } = props;

  return (
    <div className="last-updated" role="text">
      <Text size="4" color="secondary">
        <Translate
          id="grammar/timestampCreated"
          values={{
            time: <Timestamp timestamp={created} />
          }}
        />
      </Text>
    </div>
  );
};
