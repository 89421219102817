import { useQueryWrapper } from '../query';
import { useFetchLocation } from '../locations/hooks';
import { queryCurrentHourByLocationId } from './queries';

export function useFetchCurrentHourByLocationId({ locationId }: { locationId?: string }) {
  const { data: location } = useFetchLocation({ locationId });

  return useQueryWrapper({
    ...queryCurrentHourByLocationId({ locationId }),
    enabled: location != null && location.hasCurrentHour
  });
}
