import { createTimeLabel } from '../../lib/helpers/time';
import { useTranslate } from '../../lib/hooks/useTranslate';

interface IProps {
  timestamp: string;
}

export function Timestamp(props: IProps) {
  const { timestamp } = props;

  const translate = useTranslate();

  const timestampLabel = createTimeLabel({
    time: timestamp,
    type: 'hour-with-minutes',
    translate
  });

  return <time dateTime={timestamp}>{timestampLabel}</time>;
}
